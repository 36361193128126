<template>
    <b-navbar id="template-header"
        class="default-layout-navbar admin-header col-lg-12 col-12 p-0 fixed-top d-flex flex-row" toggleable="lg">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <router-link class="navbar-brand brand-logo mr-4 ml-1" to="/">
                <!-- <img src="@/public/assets/images/watch-logo.png" alt="logo"/> -->
                <img src="@/public/assets/images/cheapcomfort-logo.svg" alt="image" class="site-logo" />
            </router-link>
            <router-link class="navbar-brand brand-logo-mini" to="/">
                <!-- <img src="@/public/assets/images/watch-logo.png" alt="logo"/> -->
                <img src="@/public/assets/images/cheapcomfort-logo.svg" alt="image" class="site-logo" />
            </router-link>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-between ml-auto ml-lg-0">
            <!-- <button
                    class="navbar-toggler navbar-toggler align-self-center d-lg-block"
                    type="button"
                    @click="toggleSidebar()"
            >
                <span class="ti-layout-grid2"></span>
            </button> -->

            <div class="nav-search d-none d-lg-block">
                <div class="page-title">
                    <h2>{{ name }}</h2>
                </div>
            </div>
            <b-navbar-nav class="navbar-nav-right ml-auto">

                <!-- <b-nav-item-dropdown right class="preview-list mr-1 d-none">
                        <template slot="button-content">
                            <div class="nav-link count-indicator dropdown-toggle">
                                <i class="ti-email mx-0 d-none"></i>
                            </div>
                        </template>
<h6 class="p-3 mb-0">Messages</h6>
<b-dropdown-item class="preview-item">
    <div class="preview-thumbnail">
        <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic" />
    </div>
    <div class="preview-item-content flex-grow">
        <h6 class="preview-subject ellipsis font-weight-normal">David Grey</h6>
        <p class="font-weight-light small-text text-muted mb-0">The meeting is cancelled</p>
    </div>
</b-dropdown-item>
<b-dropdown-item class="preview-item">
    <div class="preview-thumbnail">
        <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic" />
    </div>
    <div class="preview-item-content flex-grow">
        <h6 class="preview-subject ellipsis font-weight-normal">Tim Cook</h6>
        <p class="font-weight-light small-text text-muted mb-0">New product launch</p>
    </div>
</b-dropdown-item>
<b-dropdown-item class="preview-item">
    <div class="preview-thumbnail">
        <img src="https://via.placeholder.com/36X36" alt="image" class="profile-pic" />
    </div>
    <div class="preview-item-content flex-grow">
        <h6 class="preview-subject ellipsis font-weight-normal">Johnson</h6>
        <p class="font-weight-light small-text text-muted mb-0">Upcoming board meeting</p>
    </div>
</b-dropdown-item>
</b-nav-item-dropdown>
<b-nav-item-dropdown right class="preview-list">
    <template slot="button-content">
                            <div class="nav-link count-indicator dropdown-toggle">
                                                    <i class="ti-bell mx-0"></i> 
                                    <span class="count"></span> 
                                <button type="button" class="btn btn-primary btn-sm" v-if="super_admin_email"
                                        @click="loginAs">Back to Super Admin
                                </button>
                            </div>
                        </template>
</b-nav-item-dropdown> -->
                <!-- <b-nav-item-dropdown right>
                    <template #button-content>
                        <i class="ti-world"></i>{{ selectedLanguage }}
                    </template>
                    <b-dropdown-item @click="changeLanguage('en')">English</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguage('es')">Spanish</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguage('de')">German</b-dropdown-item>
                    <b-dropdown-item @click="changeLanguage('fr')">French</b-dropdown-item>

                </b-nav-item-dropdown> -->
                <b-nav-item-dropdown right class="nav-profile">
                    <template slot="button-content">
                        <span class="nav-link dropdown-toggle" id="profileDropdown" href="javascript:void(0);"
                            data-toggle="dropdown" aria-expanded="false">
                            <div class="nav-profile-img">
                                <img src="https://staging.cheapcomforts.com/public/simp.jpg" alt="image" />
                            </div>
                        </span>
                    </template>

                    <b-dropdown-item class="preview-item">
                        <router-link to="/settings">
                            <i class="ti-settings text-primary"></i> Settings
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item class="preview-item" @click="logout()">
                        <i class="ti-power-off text-primary"></i> Logout
                    </b-dropdown-item>
                </b-nav-item-dropdown>
                <!--                <b-nav-item class="nav-logout d-none d-lg-block nav-settings">-->
                <!--                    <i class="ti-more" @click="togglesettingsPannel()"></i>-->
                <!--                </b-nav-item>-->
            </b-navbar-nav>
            <button class="navbar-toggler navbar-toggler-right align-self-center" type="button"
                @click="toggleMobileSidebar()">
                <span class="mdi mdi-menu"></span>
            </button>
        </div>
    </b-navbar>
</template>

<script>
import Vue from 'vue';
import router from "../../router"
import API from '@/api'

export default {
    name: "app-header",
    data() {
        return {
            selectedLanguage: 'EN',
            clientSettings: [],
            name: '',
            super_admin_email: '',
        };
    },

    methods: {
        changeLanguage(language) {
            // Your language change logic here
            Vue.prototype.$EventBus.$emit('changeLanguage', language);
            this.selectedLanguage = language.toUpperCase();

            console.log(`Selected language: ${language}`);
        },
        toggleSidebar: () => {
            document.querySelector("body").classList.toggle("sidebar-icon-only");
        },
        toggleMobileSidebar: () => {
            document.querySelector("#sidebar").classList.toggle("active");
        },
        togglesettingsPannel: () => {
            document.querySelector("#right-sidebar").classList.toggle("open");
        },
        logout() {
            var admin_user = JSON.parse(localStorage.getItem('user'));
            var citizen_user = JSON.parse(localStorage.getItem('spotterClientSettings'));
            if (citizen_user) {
                if (citizen_user['user_id'] === admin_user['id']) {
                    this.$session.clear();
                    localStorage.removeItem('userPermissions');
                    localStorage.removeItem('isLoggedin');
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                    localStorage.removeItem('super_admin_email');
                    router.push("/Admin");
                } else {
                    API.logout(
                        data => {
                            localStorage.removeItem('super_admin_email');
                            this.$session.clear();
                            localStorage.removeItem('userPermissions');
                            localStorage.removeItem('isLoggedin');
                            localStorage.removeItem('email');
                            localStorage.removeItem('password');
                            router.push("/Admin");
                        },
                        err => {
                        }
                    )
                }
            } else {
                API.logout(
                    data => {
                        this.$session.clear();
                        localStorage.removeItem('userPermissions');
                        localStorage.removeItem('isLoggedin');
                        localStorage.removeItem('email');
                        localStorage.removeItem('password');
                        router.push("/Admin");
                    },
                    err => {
                    }
                )
            }
        },
        getClientSettings() {
            this.clientSettings = JSON.parse(localStorage.getItem('client_settings'));
            if (this.clientSettings) {
                this.name = this.clientSettings.name;
            }
        },
        loginAs() {
            let data = {
                email: this.super_admin_email
            }
            API.loginAs(data,
                data => {
                    if (typeof data.token !== "undefined") {
                        localStorage.removeItem('super_admin_email');
                        this.super_admin_email = '';
                        localStorage.setItem('isLoggedin', data.token)
                        localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                        localStorage.setItem('user', JSON.stringify(data.user))
                        localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                        router.push("/dashboard")
                        router.go("/dashboard")
                    }
                },
                err => {
                }
            )
        }
    },
    beforeMount() {
        // this.getClientSettings();
        // this.super_admin_email = localStorage.getItem('super_admin_email');
    },
};
</script>
<style>
.fixed-top,
.horizontal-menu.fixed-on-scroll .bottom-navbar {
    position: sticky !important;
}
</style>