<template>
    <section class="app-footer">
        <footer class="footer">
            <div class="dis-flex">
                <span class=""> 
                     {{ $t('footer.Allrights', { year: new Date().getFullYear() }) }}  
                    <!--          <a href="http://www.bootstrapdash.com/" target="_blank">Bootstrapdash</a>. All rights reserved.-->
                </span>

                <!-- <p class="ml-auto mb-0"><span><a href="https://watchspotter.net/privacy-policy/" target="_blank">Privacy Policy</a></span>
                    <span class=""> |</span> <span><a href="https://www.watchspotter.net/terms-and-conditions" target="_blank">Terms and Conditions</a> | </span> <span><a href="https://www.watchspotter.net/contact" target="_blank">Contact Us</a></span></p> -->
            </div>
        </footer>
    </section>
</template>

<script>
    export default {
        name: "app-footer"
    };
</script>
