import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        click: 0,
    },
    getters: {
        myStoreValue: state => !!state.click
    },
    mutations: {
        increment(state) {
            state.click++;
        },
        decrement(state) {
            state.click = 0;
        }
    },
    actions: {},
    modules: {}
})
