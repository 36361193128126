
import guest_layout from "../guest_layout";
import guest_login_layout from "../guest/guest_login_layout";
export default [
    {
        path: "/",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "home",
                component: () => import("@/guest/Home")
            }
        ]
    },
    {
        path: "/privacypolicy",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "privacypolicy",
                component: () => import("@/guest/privacypolicy")

            }
        ]
    },
    {
        path: "/Faq",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "faq",
                component: () => import("@/guest/faqs")

            }
        ]
    },
    {
        path: "/About",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "about",
                component: () => import("@/guest/about_us")

            }
        ]
    },
    {
        path: "/my_bookings",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "my_bookings",
                component: () => import("@/guest/my_bookings")

            }
        ]
    },
    {
        path: "/add_property",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "add_property",
                component: () => import("@/property/add_property")
            }
        ]
    },
    {
        path: "/income_statistics",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "income_statistics",
                component: () => import("@/guest/income_statistics")

            }
        ]
    },
    {
        path: "/manage_properties",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "manage_properties",
                component: () => import("@/guest/manage_property")

            }
        ]
    },
    {
        path: "/property_details",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "property_details",
                component: () => import("@/guest/property_details")
            }
        ]
    },
    {
        path: "/property/detail/:id",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "property_detail",
                component: () => import("@/guest/propertyDetail")

            }
        ]
    },
    {
        path: "/property/rating",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "property_rating",
                component: () => import("@/guest/RatingReviews")
            }
        ]
    },
    {
        path: "/property/payment",
        component: guest_layout,
        children: [
            {
                path: "",
                name: "property_payment",
                component: () => import("@/guest/payment")
            }
        ]
    },
    {
        path: "/home/login",
        component: guest_login_layout,
        children: [
            {
                path: "",
                name: "guest_login",
                component: () => import("@/guest/Login")
            }
        ]
    },
    {
        path: "/home/signup",
        component: guest_login_layout,
        children: [
            {
                path: "",
                name: "guest_signup",
                component: () => import("@/guest/Register")
            }
        ]
    },
];