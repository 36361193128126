<template>
    <section class="main-view">
        <div class="container-scroller">
            <Header/>
            <div class="container-fluid page-body-wrapper client-dashbboard admin-content">
                <SettingsPannel></SettingsPannel>
                <Sidebar/>
                <div class="main-panel">
                    <div class="content-wrapper">
                        <router-view></router-view>
                    </div>
                    <!-- content wrapper ends -->
                    <Footer/>
                </div>
                <!-- main panel ends -->
            </div>
            <!-- page-body-wrapper ends -->
        </div>
    </section>
</template>

<script>
    import Header from "./partials/Header";
    import Sidebar from "./partials/Sidebar";
    import Footer from "./partials/Footer";
    import SettingsPannel from "./partials/SettingsPannel";

    export default {
        name: "layout",
        components: {
            Header,
            Sidebar,
            Footer,
            SettingsPannel
        }, data() {
            return {
                getPermissionsData: [],
            };
        }, beforeMount() {
            this.getPermissions();
        }, mounted() {
            // if (this.$route.name != 'dashboard' && this.$route.name != 'settings') {
            //     var check = this.permissionExist(this.$route.name);
            //     if (!check) {
            //         this.$router.push({name: 'error-404'})
            //     }
            // }
        }, methods: {
            getPermissions() {
                // this.getPermissionsData = JSON.parse(this.$session.get('userPermissions'));
                this.getPermissionsData = JSON.parse(localStorage.getItem('userPermissions'));
            },
            permissionExist(param) {
                var check = this.getPermissionsData.includes(param);
                if (check) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    };
</script>
<style scoped>
/* .container-fluid.page-body-wrapper.client-dashbboard .admin-content{
    padding-top: 70px !important;
} */
</style>
