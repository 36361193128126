import layout from "../layout";
import full_screen_layout from "../full_screen_layout";
export default [
    {
        path: "/Admin",
        component: {
            render(c) {
                return c("router-view");
            },
        },
        children: [
            {
                path: "",
                name: "login",
                component: () => import("@/pages/samples/auth-pages/login"),
                meta: {
                    title: "Admin",
                },
            },
            {
                path: "/forget-password",
                name: "forget-password",
                component: () => import("@/SuperAdmin/forget_password")
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: () => import("@/SuperAdmin/sign_up")
            },
            {
                path: "/reset-password/:token",
                name: "reset-password",
                component: () => import("@/SuperAdmin/reset_passwords")
            },
        ]
    },
    {
        path: "/Admin",
        component: layout,
        children: [
            {
                path: "",
                name: "dashboard",
                component: () => import("@/property/dashboard"),

            }
        ]
    }, 
    {
        path: "/add/property/",
        component: layout,
        children: [
            {
                path: "",
                name: "add_property",
                component: () => import("@/property/add_property")
            }
        ]
    },
    {
        path: "/properties",
        component: layout,
        children: [
            {
                path: "",
                name: "properties",
                component: () => import("@/property/properties")
            }
        ]
    },
    {
        path: "/users/list",
        component: layout,
        children: [
            {
                path: "",
                name: "users_list",
                component: () => import("@/SuperAdmin/users_list")
            }
        ]
    },
    {
        path: "/booking/list",
        component: layout,
        children: [
            {
                path: "",
                name: "bookings_list",
                component: () => import("@/property/bookings")
            }
        ]
    },
    {
        path: "/bookings",
        component: layout,
        children: [
            {
                path: "",
                name: "bookings",
                component: () => import("@/property/Property_owner_bookings")
            }
        ]
    },
    {
        path: "/properties/list",
        component: layout,
        children: [
            {
                path: "",
                name: "properties_list",
                component: () => import("@/property/properties_list")
            }
        ]
    },
    {
        path: "/view/ratings",
        component: layout,
        children: [
            {
                path: "",
                name: "view_rating",
                component: () => import("@/property/view_rating")
            }
        ]
    },
    {
        path: "/properties/detail",
        component: layout,
        children: [
            {
                path: "",
                name: "properties_detail",
                component: () => import("@/property/properties_detail")
            }
        ]
    },
    {
        path: "/user/users",
        component: layout,
        children: [
            {
                path: "",
                name: "users",
                component: () => import("@/SuperAdmin/users")
            }
        ]
    },
    {
        path: "/user/roles",
        component: layout,
        children: [
            {
                path: "",
                name: "roles",
                component: () => import("@/SuperAdmin/roles")
            }
        ]
    },
    {
        path: "/dashboard/log",
        component: layout,
        children: [
            {
                path: "",
                name: "log",
                component: () => import("@/pages/samples/auth-pages/login")
            }
        ]
    },

    {
        path: "/error-pages",
        component: {
            render(c) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "error-404",
                name: "error-404",
                component: () => import("@/pages/samples/error-pages/error-404")
            },
            {
                path: "error-500",
                name: "error-500",
                component: () => import("@/pages/samples/error-pages/error-500")
            }
        ]
    },
    {
        path: "*",
        redirect: "/error-404",
        component: {
            render(c) {
                return c("router-view");
            }
        },
        children: [
            {
                path: "error-404",
                component: () => import("@/pages/samples/error-pages/error-404")
            }
        ]
    }
];