<template>
    <div class="close-header-title">
        
    <!-- End Footer Section -->
    <a href="javascript:void(0);" class="scrollup" style="display: none;"><i class="ion-ios-arrow-up"></i></a>
    </div>
</template>
<script>
export default {
    name: 'CustomerFooter',
    mounted(){
    },
    methods: {
      
    }

}
</script>
<style scoped>
    .scrollup {
        background-color: #70d3ff;
        color: #fff;
    }
    .scrollup:hover {
        background-color: #1198ec;
        color: #fff;
    }
    .goog-te-banner-frame.skiptranslate {
        display: none !important;
    }
    body {
        top: 0px !important;
    }
    .goog-te-gadget .goog-te-combo {
        color: black !important;
    }
    #google_translate_element{
        margin-left: 18px;
    }
    .skiptranslate.goog-te-gadget{
        font-size:0;
    }

    .footer {
        clear: both;
        padding: 22px 0px;
        /* background-color: #363f4d; */
        background-color: #f7f7f7;
    }
    .container:before, .container:after {
        display: table;
        content: " ";
    }
    .footer-content{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
    }
    .footer-menu ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .footer-content .footer-menu ul li {
        display: inline-block;
        padding: 10px;
    }
    ul li {
        list-style: none;
    }
    @media (min-width: 1200px){
        .container {
            width: 1170px;
        }
    }

    @media (max-width: 1199px){
        #google_translate_element{margin-left: 0;}
        .copyright-content {
            text-align: center;
            width: 100%;
        }

        .footer-content {
            justify-content: center;
        }

        .footer-menu {
            width: 100%;
        }

        .footer-menu ul {
            justify-content: center;
        }
    }

    @media (max-width: 767px){
        .footer-content {display: block;}
        .footer-content .footer-menu ul li{display: block;width: 100%;}

    }
    /*NEW*/
    .skiptranslate.goog-te-gadget {
        line-height: 0;
    }
</style>
