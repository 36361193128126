import Vue from "vue";
import Router from "vue-router";
import API from '@/api';
import admin from "./admin";
import guest from "./guest";
Vue.use(Router);

const router = new Router({
    linkExactActiveClass: "active",
    scrollBehavior: () => ({y: 0}),
    mode: "history",
    base: "/",
    routes: [...admin,...guest],
});

export default router
router.beforeEach((to, from, next) => {
    if (to.path.split("/")[1] == "Admin") {
      let email = localStorage.getItem("email");
      let password = localStorage.getItem("password");
      if (
        email !== null &&
        password !== null &&
        localStorage.getItem("isLoggedin") === null
      ) {
        let data = {
          email: email,
          password: password,
        };
        console.log("after redirect:" + to.name);
        API.login(
          data,
          (data) => {
            if (typeof data.token !== "undefined") {
              localStorage.setItem("isLoggedin", data.token);
              localStorage.setItem(
                "userPermissions",
                JSON.stringify(data.permission)
              );
              Vue.prototype.$session.set("isLoggedin", data.token);
              Vue.prototype.$session.set(
                "userPermissions",
                JSON.stringify(data.permission)
              );
              if (to.name === "") {
                next({ name: "dashboard" });
              } else {
                next({
                  name: to.name,
                });
              }
            }
          },
          (err) => { }
        );
      } else {
        if (
          next.name === "forget-password" &&
          to.name !== "reset-password" &&
          localStorage.getItem("isLoggedin") === null
        ) {
          next({
            name: "forget-password",
          });
        } else if (
          to.name !== "login" &&
          to.name !== "forget-password" &&
          to.name !== "reset-password" &&
          localStorage.getItem("isLoggedin") === null
        ) {
          next({
            name: "login",
          });
        } else if (
          to.name === "login" &&
          localStorage.getItem("isLoggedin") !== null
        ) {
          next({ name: "dashboard" });
        } else {
          next();
        }
      }
    } else if (to.path.split("/")[1] == "home") {
      document.title = to.meta?.title ?? "default title";
      let userDetails = JSON.parse(localStorage.getItem('userDetails'));
      let userToken = localStorage.getItem("userLoginToken");
      if (to.name == "guest_login" && userToken != null) {
        next({ name: "home" });
      } else if (to.name == "home" && userToken == null) {
        next({ name: "guest_login" });
      } else if (to.name == "home/signup" && userToken == null) {
        next({ name: "guest_signup" });
      } else {
        next();
      }
    } else {
      next();
    }
  });
