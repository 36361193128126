<template>
    <div class="close-header-title">
        <footer>
            <div class="footer-btm footer">
                <div class="container">
                    <div class="footer-content">
                        <div class="copyright-content text-dark">
                            <p>{{ $t('footer.copyright', { year: new Date().getFullYear() }) }}</p>
                        </div>
                        <ul class="footer-links">
                            <li><a href="/privacypolicy">Privacy Policy</a></li>
                            <li class="ml-3"><a @click="faqclick">FAQ</a></li>
                            <li class="ml-3"><a @click="aboutus">About Us</a></li>
                        </ul>
                        <div class="social-media-buttons">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/YourPage" target="_blank" class="social-media-icon">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li><a href="https://twitter.com/YourProfile" target="_blank" class="social-media-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                                </a></li>
                                <li><a href="https://www.linkedin.com/in/YourProfile" target="_blank" class="social-media-icon">
                                    <i class="fa fa-linkedin"></i>
                                </a></li>
                                <li><a href="https://wa.me/YourNumber" target="_blank" class="social-media-icon">
                                    <i class="fa fa-comment" aria-hidden="true"></i>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <a href="javascript:void(0);" class="scrollup" style="display: none;"><i class="ion-ios-arrow-up"></i></a>

        <!-- Inline SVG Definitions for Testing -->
        <svg style="display: none;">
            <symbol id="icon-facebook" viewBox="0 0 24 24">
                <path d="M22.675 0h-21.35c-0.729 0-1.325 0.597-1.325 1.325v21.351c0 0.729 0.597 1.324 1.325 1.324h11.495v-9.294h-3.123v-3.622h3.123v-2.669c0-3.1 1.894-4.788 4.658-4.788 1.325 0 2.465 0.099 2.797 0.143v3.244l-1.917 0.001c-1.504 0-1.794 0.715-1.794 1.762v2.307h3.588l-0.467 3.622h-3.121v9.294h6.116c0.729 0 1.325-0.595 1.325-1.324v-21.351c0-0.728-0.596-1.325-1.325-1.325z"></path>
            </symbol>
            <symbol id="icon-twitter" viewBox="0 0 24 24">
                <path d="M24 4.557c-0.883 0.392-1.832 0.656-2.828 0.775 1.017-0.609 1.798-1.574 2.165-2.724-0.951 0.564-2.005 0.974-3.127 1.195-0.897-0.955-2.173-1.552-3.594-1.552-2.719 0-4.924 2.205-4.924 4.923 0 0.386 0.044 0.763 0.128 1.125-4.091-0.206-7.719-2.165-10.148-5.144-0.423 0.724-0.666 1.562-0.666 2.456 0 1.694 0.863 3.188 2.177 4.066-0.802-0.025-1.558-0.246-2.218-0.614v0.062c0 2.366 1.684 4.342 3.918 4.787-0.41 0.111-0.841 0.171-1.287 0.171-0.314 0-0.615-0.03-0.912-0.086 0.615 1.921 2.397 3.318 4.509 3.356-1.653 1.296-3.734 2.07-5.996 2.07-0.39 0-0.779-0.023-1.17-0.068 2.137 1.373 4.676 2.172 7.404 2.172 8.882 0 13.742-7.355 13.742-13.742 0-0.209-0.004-0.418-0.014-0.626 0.943-0.681 1.762-1.532 2.409-2.505z"></path>
            </symbol>
            <symbol id="icon-linkedin" viewBox="0 0 24 24">
                <path d="M4.98 3.5c0 1.382-1.119 2.5-2.5 2.5s-2.5-1.118-2.5-2.5 1.119-2.5 2.5-2.5 2.5 1.118 2.5 2.5zM0.5 23.5v-15h4v15h-4zM8.5 8.5h-0.041c-0.015 0-0.030 0-0.045 0-0.015 0-0.030 0-0.045 0h-0.041v15h4v-8.19c0-1.91 0.347-3.621 2.521-3.621s2.436 1.826 2.436 3.718v8.093h4v-8.666c0-4.373-2.318-6.406-5.423-6.406-2.369 0-3.388 1.325-3.943 2.258v-1.862h-4v0.037z"></path>
            </symbol>
            <symbol id="icon-whatsapp" viewBox="0 0 24 24">
                <path d="M12 0c-6.627 0-12 5.373-12 12 0 2.171 0.575 4.214 1.667 6.043l-1.15 4.171 4.296-1.122c1.771 1.037 3.782 1.606 5.937 1.606 6.627 0 12-5.373 12-12s-5.373-12-12-12zM12 21.5c-1.814 0-3.536-0.538-5.006-1.554l-0.362-0.229-2.559 0.668 0.686-2.542-0.228-0.362c-1.014-1.471-1.553-3.194-1.553-5.008 0-5.247 4.253-9.5 9.5-9.5s9.5 4.253 9.5 9.5-4.253 9.5-9.5 9.5z"></path>
            </symbol>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'CustomerFooter',
    mounted() {
    },
    methods: {
        faqclick() { 
            this.$router.push({ name: 'faq' });
        },
        aboutus() { 
            this.$router.push({ name: 'about' });
        }
    }
}
</script>

<style scoped>
.scrollup {
    background-color: #70d3ff;
    color: #fff;
}

.scrollup:hover {
    background-color: #1198ec;
    color: #fff;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
.footer-content .social-media-buttons ul li .social-media-icon {
    color: #000;
    font-size: 16px;
}
.footer-content .social-media-buttons ul li .social-media-icon:hover{
    color: #1198ec;
}
body {
    top: 0px !important;
}

.goog-te-gadget .goog-te-combo {
    color: black !important;
}

#google_translate_element {
    margin-left: 18px;
}

.skiptranslate.goog-te-gadget {
    font-size: 0;
}

.footer {
    clear: both;
    padding: 22px 0px;
    /* background-color: #363f4d; */
    background-color: #fff;
}

.container:before,
.container:after {
    display: table;
    content: " ";
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.footer-content p {
    margin: 0;
}

.footer-content ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding: 0;
}

.footer-content ul li a {
    color: #000000;
    cursor: pointer;
    font-weight: 500;
}
.footer-content ul li a:hover, .footer-content ul li a:hover svg {
    color: #1198ec;
    fill: #1198ec;
}
ul li {
    list-style: none;
}
.social-media-buttons ul li {
    margin-left: 15px;
}
.social-media-buttons ul li svg {
    width: 100%;
    max-width: 16px;
    max-height: 16px;
}
</style>
